var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-4"},[_c('a-table',{attrs:{"toolbar-column-filter":"","columns":_vm.columns,"source":_vm.lessons.data,"pagination":_vm.lessons,"loading":_vm.loadings.lessons,"skeleton-count":10,"overflow":""},on:{"paginate":function($event){return _vm.fetch({ page: $event })}},scopedSlots:_vm._u([{key:"td(absences)",fn:function(ref){
var item = ref.item;
return [_c('p',{class:[ ("text-" + (item.pivot.attendance === false ? 'red' : 'green') + "-500 font-bold") ]},[_vm._v(_vm._s(_vm._f("boolean")(item.pivot.attendance,'Sin', ''))+" inasistencia")]),_c('p',{class:[ ("text-" + (item.pivot.delayed === true ? 'red' : 'green') + "-500 font-bold") ]},[_vm._v(_vm._s(_vm._f("boolean")(item.pivot.delayed,'', 'Sin', 'Sin'))+" retraso")])]}},{key:"toolbar(search)",fn:function(){return [_c('h3',{staticClass:"text-2xl px-2"},[_vm._v("Clases")])]},proxy:true},{key:"toolbar",fn:function(){return [_c('v-select',{staticClass:"w-48",attrs:{"loading":_vm.loadings.periods,"options":_vm.periods,"placeholder":"Período","reduce":function ($0) { return $0.value; }},on:{"input":function($event){return _vm.fetch({ page: 1 })}},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})]},proxy:true},{key:"td(actions)",fn:function(ref){
var item = ref.item;
return [_c('a-dropdown',{scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('a-dropdown-item',{on:{"click":function($event){return _vm.attachAbsence({
                          student: item.pivot.student_id,
                          lesson: item.pivot.lesson_id,
                          attendance: !item.pivot.attendance,
                          note: item.pivot.note
                      })}}},[_vm._v(" "+_vm._s(_vm._f("boolean")(item.pivot.attendance,'Registrar inasistencia', 'Eliminar inasistencia'))+" ")]),_c('a-dropdown-item',{on:{"click":function($event){return _vm.attachAbsence({
                          student: item.pivot.student_id,
                          lesson: item.pivot.lesson_id,
                          delayed: !item.pivot.delayed,
                          note: item.pivot.note
                      })}}},[_vm._v(" "+_vm._s(_vm._f("boolean")(item.pivot.delayed,'Eliminar retraso', 'Registrar retraso'))+" ")])]},proxy:true}],null,true)},[_c('a-button',{attrs:{"flat":""}},[_vm._v("Opciones")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }