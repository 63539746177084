<template>
  <div class="p-4">
    <a-table
      toolbar-column-filter
      :columns="columns"
      :source="lessons.data"
      :pagination="lessons"
      :loading="loadings.lessons"
      :skeleton-count="10"
      @paginate="fetch({ page: $event })"
      overflow>
      <template #td(absences)="{ item }">
        <p :class="[ `text-${item.pivot.attendance === false ? 'red' : 'green'}-500 font-bold` ]">{{ item.pivot.attendance | boolean('Sin', '') }} inasistencia</p>
        <p :class="[ `text-${item.pivot.delayed === true ? 'red' : 'green'}-500 font-bold` ]">{{ item.pivot.delayed | boolean('', 'Sin', 'Sin') }} retraso</p>
      </template>

      <template #toolbar(search)>
        <h3 class="text-2xl px-2">Clases</h3>
      </template>

      <template #toolbar>
        <v-select
          class="w-48"
          :loading="loadings.periods"
          :options="periods"
          v-model="period"
          placeholder="Período"
          :reduce="$0 => $0.value"
          @input="fetch({ page: 1 })" />
      </template>

      <template #td(actions)="{ item }">
        <a-dropdown>
          <a-button flat>Opciones</a-button>
          <template #menu>
            <a-dropdown-item @click="attachAbsence({
                            student: item.pivot.student_id,
                            lesson: item.pivot.lesson_id,
                            attendance: !item.pivot.attendance,
                            note: item.pivot.note
                        })">
              {{ item.pivot.attendance | boolean('Registrar inasistencia', 'Eliminar inasistencia') }}
            </a-dropdown-item>
            <a-dropdown-item @click="attachAbsence({
                            student: item.pivot.student_id,
                            lesson: item.pivot.lesson_id,
                            delayed: !item.pivot.delayed,
                            note: item.pivot.note
                        })">
              {{ item.pivot.delayed | boolean('Eliminar retraso', 'Registrar retraso') }}
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    lessons: {},
    loadings: {
      periods: false,
      lessons: false
    },
    query: {
      limit: 15,
      page: 1,
      with: 'course,teacher,section',
      registered: 1
    },
    periods: [],
    period: null
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user
    }),
    columns() {

      let columns = [
        { title: 'Fecha', key: 'date' },
        { title: 'Tema', key: 'subject' },
        { title: 'Materia', key: 'course.title' },
        { title: 'Sección', key: 'section.letter_code' },
        { title: 'Semestre', key: 'course', mutate: ({ key }) => `${key.code.slice(-1)}` },
        { title: 'Retraso e inasistencia', slot: 'absences', key: 'absences' },
      ]

      if (this.user.isAdmin) {
        columns.push({ title: 'Profesor', key: 'teacher', mutate: ({ key }) => `${key.first_name} ${key.last_name}` })
      }

      if (this.user.isTeacher || this.user.isCoordinator) {
        columns.push({ title: 'Acciones', slot: 'actions' })
      }

      return columns
    }
  },
  methods: {
    attachAbsence({ attendance, delayed, student, lesson, note }) {

      let context = { note }

      if (attendance !== null && attendance !== undefined) {
        context.attendance = attendance
      }

      if (delayed !== null && delayed !== undefined) {
        context.delayed = delayed
      }

      this.$repository.students
        .updateLesson({ student, lesson }, context)
        .then(() => {
          this.fetch({ page: 1})
        })
    },
    fetch(query) {
      this.loadings.lessons = !this.loadings.lessons
      // const query = { limit: 15, page: page, with: 'course,teacher,section', registered: 1, period }
      this.query.page = query.page ? query.page : 1
      const endpoint = this.user.isAdminGroup || this.user.isCoordinator
        ? this.$repository.students.lessons(this.$route.params.student, { ...this.query, period: this.period })
        : this.$repository.courseSections.studentLessons({ ...this.$route.params }, { ...this.query, period: this.period })

      endpoint.then(({ data }) => {
        this.lessons = data
      }).finally(() => this.loadings.lessons = !this.loadings.lessons)
    }
  },
  mounted() {
    this.loadings.periods = !this.loadings.periods
    this.$repository.periods.index({
      limit: 0,
      order_by: 'id',
    })
      .then(({ data }) => {
        this.periods = data.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))
      })
      .finally(() => this.loadings.periods = !this.loadings.periods)
  }
}
</script>
